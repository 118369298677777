import { Search } from '@mui/icons-material';
import { InputAdornment, InputBase, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../../redux';

export const SearchFontsInput = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectors.selectSearchValue);

  const handleChange = ({ target }) => {
    dispatch(actions.setSearchValue(target.value));
  };
  return (
    <InputBase
      onChange={handleChange}
      placeholder="Search fonts"
      value={value}
      variant="standard"
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
    // <TextField
    //   onChange={handleChange}
    //   placeholder="Search fonts"
    //   value={value}
    //   variant="standard"
    //   InputProps={{
    //     startAdornment: (
    //       <InputAdornment position="start">
    //         <Search />
    //       </InputAdornment>
    //     ),
    //   }}
    // ></TextField>
  );
};
