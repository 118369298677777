import { createSlice } from '@reduxjs/toolkit';
import { K } from '../../configs';

const namespace = K.reduxNamespaces.APPLICATION;

const initialState = {
  fonts: [],
  fontsObject: {},
  searchValue: '',
  theme: K.THEME_MODE.light,
  fetchFontsStatus: K.LOADING_STATUS.idle,
  hoveredFont: null,
  fontSize: 40,
  previewMode: K.previewMode.sentence,
  previewText: K.previewTextDefault.split(' ').slice(0, 9).join(' '),
};

export const appSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setPreviewMode: (state, action) => {
      const { payload: mode } = action;
      state.previewMode = mode;
      switch (mode) {
        case K.previewMode.paragraph:
          state.fontSize = 18;
          state.previewText = K.previewTextDefault;
          break;
        case K.previewMode.sentence:
          state.fontSize = 40;
          state.previewText = K.previewTextDefault
            .split(' ')
            .slice(0, 9)
            .join(' ');
      }
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setFontSize: (state, action) => {
      state.fontSize = action.payload;
    },
    setHoveredFont: (state, action) => {
      state.hoveredFont = action.payload;
    },
    setPreviewText: (state, action) => {
      state.previewText = action.payload;
    },
    setFontsLoadingStatus: (state, action) => {
      state.fetchFontsStatus = action.payload;
    },
    setFonts: (state, action) => {
      state.fonts = action.payload;
    },
    setFontsObject: (state, action) => {
      state.fontsObject = action.payload;
    },
    toggleTheme: (state) => {
      if (state.theme === K.THEME_MODE.light) {
        state.theme = K.THEME_MODE.dark;
      } else {
        state.theme = K.THEME_MODE.light;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const actions = appSlice.actions;

export default appSlice.reducer;
