import { K } from '../../configs';

const NAMESPACE = K.reduxNamespaces.APPLICATION;

export const selectFonts = (state) => state[NAMESPACE].fonts;
export const selectHoveredFont = (state) => state[NAMESPACE].hoveredFont;
export const selectSearchValue = (state) => state[NAMESPACE].searchValue;
export const selectFontsObject = (state) => state[NAMESPACE].fontsObject;
export const selectCurrentTheme = (state) => state[NAMESPACE].theme;
export const selectPreviewText = (state) => state[NAMESPACE].previewText;
export const selectLoadingStatus = (state) => state[NAMESPACE].fetchFontsStatus;
export const selectFontSize = (state) => state[NAMESPACE].fontSize;
export const selectPreviewMode = (state) => state[NAMESPACE].previewMode;
