import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import App from '../App';

// Pages
import HomePage from '../../pages/home/Home';
import FontPage from '../../pages/font/Font';

// 404
import NotFound404 from '../../pages/404/404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<NotFound404 />}>
      <Route index element={<HomePage />} />
      <Route path="/fonts/:fontName" element={<FontPage />} />
    </Route>
  )
);

const Router = () => <RouterProvider router={router} />;

export default Router;
