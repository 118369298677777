import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { K } from '../../configs';
import { selectors } from '../../redux';

export const FontCharList = ({ fontFamily }) => {
  const style = {
    fontFamily,
    justifyContent: 'space-evenly',
    gap: '10px',
  };
  const chars = K.fontCharList.split('');

  return (
    <Stack sx={style} direction={'row'} flexWrap="wrap">
      {chars.map((char, index) => (
        <FontChar char={char} key={index} />
      ))}
    </Stack>
  );
};

const FontChar = ({ char }) => {
  const fontSize = useSelector(selectors.selectFontSize);
  const size = `${Math.ceil(fontSize * 1.75)}px`;
  const style = {
    width: size,
    height: size,
    border: '2px solid #efefef',
    borderRadius: '15px',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };
  const hoverStyle = {
    transition: 'var(--transition)',
    scale: '0.9',
    '&:hover': {
      scale: '1',
    },
    '&:hover .character': {
      boxShadow:
        'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    },
  };

  return (
    <Box sx={hoverStyle}>
      <Box className="character" sx={style}>
        <Typography sx={{ fontFamily: 'inherit' }} fontSize={fontSize}>
          {char}
        </Typography>
      </Box>
    </Box>
  );
};
