import { CheckCircle, CopyAll } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontCharList } from '../../components/FontCharList/FontCharList';
import { Header } from '../../components/Header/Header';
import { selectors } from '../../redux';

const FontPage = () => {
  const { fontName } = useParams();
  const fontTitle = fontName.split('-').join(' ');
  const fontFamily = fontName.split('-').join('');
  const font = useSelector(selectors.selectFontsObject)[fontTitle];
  let cssEmbed = '';
  font.forEach((asset) => {
    cssEmbed += `

${asset.metadata.cssEmbed}
`;
  });

  const style = {
    overflowY: 'scroll',
    height: 'calc(100vh - 55px - 50px)',
    paddingTop: '50px',
    paddingBottom: '100px',
    position: 'relative',
  };

  return (
    <>
      <Header fontName={fontTitle} />
      <Stack sx={style}>
        <Section title={'Text sample with ' + fontTitle}>
          <SampleText fontFamily={fontFamily} />
        </Section>
        <Section
          title={'Embed CSS'}
          rightSection={<CopyCodesBtn cssEmbed={cssEmbed} />}
        >
          <ExpandMininizeHelper>
            <EmbedCodes cssEmbed={cssEmbed} />
          </ExpandMininizeHelper>
        </Section>
        <Section title={'Letters & glpyhs of ' + fontTitle}>
          <ExpandMininizeHelper>
            <FontCharList fontFamily={fontFamily} />
          </ExpandMininizeHelper>
        </Section>

        <Section title={'Preview components with ' + fontTitle}>
          <PreviewComponents fontFamily={fontFamily} />
        </Section>
      </Stack>
    </>
  );
};

const SampleText = ({ fontFamily }) => {
  const previewText = useSelector(selectors.selectPreviewText);
  const fontSize = useSelector(selectors.selectFontSize) + 'px';

  return (
    <Box sx={{ padding: '10px 20px', marginBottom: '50px' }}>
      <Typography sx={{ fontFamily, fontSize }}>{previewText}</Typography>
    </Box>
  );
};

const CopyCodesBtn = ({ cssEmbed }) => {
  const [copied, setCopied] = useState(false);
  const handleClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(cssEmbed);
  };
  useEffect(() => {
    const timer = setTimeout(() => setCopied(false), 1000);

    return () => clearTimeout(timer);
  }, [copied]);
  return (
    <Tooltip
      title={copied ? 'Copied CSS!' : 'Copy CSS Embed'}
      placement="right"
    >
      <IconButton onClick={handleClick}>
        {copied ? <CheckCircle sx={{ color: '#40c057' }} /> : <CopyAll />}
      </IconButton>
    </Tooltip>
  );
};
const EmbedCodes = ({ cssEmbed }) => {
  const codeStyle = {
    whiteSpace: 'break-spaces',
  };
  return (
    <Box>
      <code style={codeStyle}>{cssEmbed}</code>
    </Box>
  );
};
const Section = ({ children, title, rightSection }) => {
  const theme = useSelector(selectors.selectCurrentTheme);
  const isDarkMode = theme === 'dark';
  const childrenContainerStyle = {
    background: isDarkMode ? 'black' : '#f7f7f7',
    padding: '10px 5vw',
  };
  return (
    <Box sx={{ marginBottom: '50px' }}>
      <Box sx={{ padding: '0px 5vw', borderBottom: '1px solid #1876d0' }}>
        <SectionTitle title={title} rightSection={rightSection} />
      </Box>
      <Box sx={childrenContainerStyle}>{children}</Box>
    </Box>
  );
};
const SectionTitle = ({ title, rightSection }) => {
  const typoStyle = {
    paddingLeft: '10px',
    borderLeft: '5px solid #d0e8ff',
    width: 'fit-content',
    paddingRight: '10px',
    userSelect: 'none',
  };
  return (
    <Stack
      direction={'row'}
      alignItems="center"
      id={'section--' + title.toLowerCase().split(' ').join('-')}
      sx={{ padding: '10px 0px' }}
    >
      <Typography sx={typoStyle}>{title}</Typography>
      {rightSection}
    </Stack>
  );
};

const ExpandMininizeHelper = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useSelector(selectors.selectCurrentTheme);
  const isDarkMode = theme === 'dark';
  const style = {
    height: isExpanded ? undefined : '30vh',
    overflow: 'hidden',
    position: 'relative',
  };
  const shadowStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '80%',
    transition: 'var(--transition)',
    pointerEvents: 'none',
  };
  return (
    <Box>
      <Box sx={style}>
        {children}
        {!isExpanded && (
          <Box
            sx={{
              ...shadowStyle,
              background: (theme) =>
                `linear-gradient(0deg, ${
                  isDarkMode ? 'black' : '#f7f7f7'
                }, transparent)`,
            }}
          ></Box>
        )}
      </Box>
      <Stack
        alignItems={'center'}
        spacing={1}
        sx={{ paddingTop: '10px', paddingBottom: '50px' }}
      >
        <Divider sx={{ opacity: 0.5 }} flexItem />
        <Button onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded ? 'Minimize' : 'Expand'}
        </Button>
      </Stack>
    </Box>
  );
};

const PreviewComponents = ({ fontFamily }) => {
  return (
    <Stack spacing={'20px'} mt="10px">
      <Box>
        <Button variant="contained" sx={{ fontFamily }}>
          Example Button
        </Button>
      </Box>
      <Box style={{ fontFamily }}>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </Box>
      <Box>
        <Tooltip title={<span style={{ fontFamily }}>Example Tooltip</span>}>
          <span>Tooltip</span>
        </Tooltip>
      </Box>
    </Stack>
  );
};
export default FontPage;
