export const formatFontsObject = (fontsArr) => {
  const obj = {};
  for (const font of fontsArr) {
    const { fontName } = font.metadata;
    if (obj[fontName]) {
      obj[fontName].push(font);
    } else {
      obj[fontName] = [font];
    }
  }
  return obj;
};

export const injectFontElementCSS = (fontsArr) => {
  if (document.querySelector('#loaded-fonts')) return;

  const fontsElement = document.createElement('div');
  fontsElement.setAttribute('id', 'loaded-fonts');
  fontsElement.setAttribute(
    'style',
    `width: 0;
    height: 0;
    overflow: hidden;
    display: none;`
  );

  fontsArr.forEach((font) => {
    const style = document.createElement('style');
    style.innerHTML = font.metadata.cssEmbed;
    fontsElement.appendChild(style);
  });

  document.body.appendChild(fontsElement);
};
