import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, Slider, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { K } from '../../configs';
import { actions, selectors } from '../../redux';

export const FontSizeSlider = () => {
  const dispatch = useDispatch();
  const fontSize = useSelector(selectors.selectFontSize);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const fontSizeOptions = K.fontSizeOptions;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (_e, newSize) => {
    dispatch(actions.setFontSize(newSize));
  };

  const handleClickMenuItem = (value) => {
    dispatch(actions.setFontSize(value));

    return handleClose();
  };

  return (
    <>
      <Stack
        alignItems={'center'}
        spacing={4}
        direction="row"
        sx={{ width: '20%', color: '#5f6368' }}
      >
        <Tooltip title="Font Size">
          <Button
            id="basic-button"
            aria-controls={open ? 'font-size-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={(theme) => {
              return {
                color: 'inherit',
                padding: '5px 15px',
                paddingLeft: '20px',
                textTransform: 'none',
                '&:hover': { color: theme.palette.primary.main },
              };
            }}
          >
            {fontSize}px
            <ArrowDropDown
              sx={(theme) => {
                return {
                  color: theme.palette.primary.main,
                  transform: open ? 'scaleY(-1)' : '',
                };
              }}
            />
          </Button>
        </Tooltip>
        <Slider
          onChange={handleChange}
          value={fontSize}
          max={fontSizeOptions[fontSizeOptions.length - 1]}
          min={7}
        />
      </Stack>
      <Menu
        PaperProps={{
          sx: {
            maxHeight: '170px',
          },
        }}
        id="font-size-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {fontSizeOptions.map((value, index) => (
          <MenuItem onClick={() => handleClickMenuItem(value)} key={index}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
