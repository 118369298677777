const K = {};

K.FONT_LIST_JSON_URL = 'https://static.anthony.media/fonts/index.json';
K.THEME_MODE = { light: 'light', dark: 'dark' };
K.reduxNamespaces = { APPLICATION: 'application' };
K.LOADING_STATUS = { idle: 'idle', pending: 'pending', failed: 'failed' };
K.previewTextDefault =
  'A purely peer-to-peer version of electronic cash would allow online payments to be sent directly from one party to another without going through a financial institution.';
K.fontSizeOptions = [8, 12, 14, 20, 24, 32, 40, 64, 96, 120, 184, 280];
K.fontCharList =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890â€˜?â€™â€œ!â€(%)[#]{@}/&<-+Ã·Ã—=>Â®Â©$â‚¬Â£Â¥Â¢:;,.*';
K.previewMode = {
  custom: 'custom',
  sentence: 'sentence',
  paragraph: 'paragram',
};
export { K };
