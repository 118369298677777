import { Button, Input, InputBase, Stack, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { K } from '../../configs';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from '../../redux';

export const FontPreviewInput = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const activeMode = useSelector(selectors.selectPreviewMode);
  const [value, setValue] = useState('');
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleTypePreview = ({ target }) => {
    const { value } = target;
    setValue(value);
    dispatch(actions.setPreviewMode(K.previewMode.custom));
    dispatch(actions.setPreviewText(value));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setMode = (mode) => {
    dispatch(actions.setPreviewMode(mode));
    handleClose();
    if (mode === K.previewMode.custom) return;

    setValue('');
  };
  const renderMenuItems = () => {
    return Object.keys(K.previewMode).map((mode) => (
      <MenuItem
        sx={{ textTransform: 'capitalize' }}
        key={mode}
        onClick={() => setMode(K.previewMode[mode])}
      >
        {mode}
      </MenuItem>
    ));
  };

  const renderActiveButton = () => {
    switch (activeMode) {
      case K.previewMode.custom: {
        return 'Custom';
      }
      case K.previewMode.sentence: {
        return 'Sentence';
      }
      case K.previewMode.paragraph: {
        return 'Paragraph';
      }
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <Button
        id="preview-input-type-button"
        aria-controls={open ? 'preview-input-type-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ textTransform: 'none' }}
      >
        {renderActiveButton()} <ArrowDropDown />
      </Button>
      <InputBase
        value={value}
        onChange={handleTypePreview}
        ref={inputRef}
        placeholder="Type something"
      />
      <Menu
        id="preview-input-type-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'preview-input-type-button',
        }}
      >
        {renderMenuItems()}
      </Menu>
    </Stack>
  );
};
