import { Error } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';

export const ErrorState = ({ message }) => {
  return (
    <Paper
      sx={{
        height: '100%',
        display: 'grid',
        width: '100%',
        placeItems: 'center',
      }}
      elevation={0}
    >
      <Stack alignItems={'center'}>
        <Error sx={{ color: 'red' }} fontSize={'large'} />
        <Typography>{message}</Typography>
      </Stack>
    </Paper>
  );
};
