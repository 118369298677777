import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import { selectors } from '../../redux';
import { K } from '../../configs';
import { MainLayout } from '../MainLayout/MainLayout';
import { muiThemeOverrides } from '../../css/muiThemeOverrides';

const lightTheme = createTheme(muiThemeOverrides);
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  ...muiThemeOverrides,
});

const App = () => {
  const userTheme = useSelector(selectors.selectCurrentTheme);
  const isDarkMode = userTheme === K.THEME_MODE.dark ? true : false;

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <MainLayout />
    </ThemeProvider>
  );
};

export default App;
