import { Brightness3, LightMode } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { K } from '../../configs';
import { actions, selectors } from '../../redux';
import { FontNameDisplay } from './FontNameDisplay';
import { FontPreviewInput } from './FontPreviewInput';
import { FontSizeSlider } from './FontSizeSlider';
import { SearchFontsInput } from './SearchFontsInput';

export const Header = ({ fontName }) => {
  const isFontPage = !!fontName;
  const dispatch = useDispatch();
  const isDarkTheme =
    useSelector(selectors.selectCurrentTheme) === K.THEME_MODE.dark;

  const previewText = useSelector(selectors.selectPreviewText);

  const handleToggleTheme = () => {
    dispatch(actions.toggleTheme());
  };

  const style = {
    padding: '7px 5%',
    height: '55px',
    overflowY: 'hidden',
    borderBottom: isDarkTheme ? '1px solid #5f6368' : '1px solid #dadce0',
  };

  return (
    <Stack
      sx={style}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {!isFontPage && <SearchFontsInput />}
      {isFontPage && <FontNameDisplay fontName={fontName} />}
      <FontPreviewInput />
      <FontSizeSlider />
      <Tooltip title={`Enable ${isDarkTheme ? 'light' : 'dark'} mode`}>
        <IconButton onClick={handleToggleTheme}>
          {!isDarkTheme && <LightMode />}
          {isDarkTheme && <Brightness3 />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
