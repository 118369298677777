import { Box, Paper, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions, selectors } from '../../redux';

export const FontList = () => {
  const fontsObject = useSelector(selectors.selectFontsObject);
  const searchValue = useSelector(selectors.selectSearchValue);
  const filteredFonts = Object.keys(fontsObject).filter((val) => {
    return val
      .trim('')
      .toLocaleLowerCase()
      .includes(searchValue.toLocaleLowerCase());
  });

  const getFontsCount = () => {
    if (filteredFonts.length === 0) return 'No fonts';

    if (filteredFonts.length === 1) return '1 font';

    return `${filteredFonts.length} fonts`;
  };

  return (
    <Stack
      direction={'row'}
      gap="50px"
      sx={{
        overflowY: 'scroll',
        height: 'calc(100vh - 55px - 50px)',
        padding: '50px 5%',
        position: 'relative',
      }}
      flexWrap="wrap"
      justifyContent={'space-evenly'}
    >
      <Typography
        sx={{ position: 'absolute', top: '10px', left: '40px', opacity: 0.5 }}
      >
        {getFontsCount()}
      </Typography>
      {filteredFonts.map((fontKey, index) => {
        const font = fontsObject[fontKey];

        return <FontDisplay font={font} key={index} />;
      })}
    </Stack>
  );
};

const FontDisplay = ({ font }) => {
  const paperStyle = {
    padding: '30px',
    textDecoration: 'initial',
    borderRadius: '12px',
    boxSizing: 'border-box',
    transition: 'var(--transition)',
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
    },
    '&:hover .font-title-header': {
      opacity: 0.25,
    },
  };

  const dispatch = useDispatch();
  const amount = font.length;
  const { fontName } = font[0].metadata;
  const previewText = useSelector(selectors.selectPreviewText);
  const hoveredFont = useSelector(selectors.selectHoveredFont);
  const isOtherFontFocused = hoveredFont && fontName !== hoveredFont;
  const fontSize = useSelector(selectors.selectFontSize);
  const paperWidth = `${fontSize * 15}px`;
  const linkToURL = `fonts/${fontName.split(' ').join('-')}`;

  const notFocusedStyles = isOtherFontFocused ? { opacity: 0.1 } : {};

  const handleOnMouseOver = (hovered) => {
    if (hovered) {
      dispatch(actions.setHoveredFont(fontName));
    } else {
      dispatch(actions.setHoveredFont(null));
    }
  };

  let weightsCount = 0;

  font.forEach((f) => {
    weightsCount += f.metadata.weights.length;
  });
  return (
    <Paper
      component={Link}
      to={linkToURL}
      onMouseEnter={() => handleOnMouseOver(true)}
      onMouseLeave={() => handleOnMouseOver(false)}
      sx={{
        ...paperStyle,
        ...notFocusedStyles,
        width: paperWidth,
        minWidth: '400px',
      }}
    >
      <Stack
        className="font-title-header"
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
        pb="10px"
        sx={{
          borderBottom: '1px solid #dadce0',
          transition: 'var(--transition)',
        }}
        mb="20px"
      >
        <Typography sx={{ opacity: 0.9 }} variant="h6">
          {fontName}
        </Typography>
        <Typography sx={{ color: '#5f6368' }} variant="caption">
          {weightsCount} style{weightsCount === 1 ? '' : 's'}
        </Typography>
      </Stack>
      <Box
        sx={{
          fontFamily: `${fontName.split(' ').join('')}`,
          fontSize: fontSize + 'px',
          wordBreak: 'break-word',
        }}
      >
        {previewText}
      </Box>
    </Paper>
  );
};
