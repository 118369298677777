import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { ErrorState } from '../../components/ErrorState/ErrorState';

const NotFound404 = () => {
  const style = {
    height: 'calc(100vh - 50px)',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
  };

  return (
    <Box sx={style}>
      <Stack spacing={2}>
        <ErrorState message={'Page not found'} />
        <Button startIcon={<ArrowBack />} component={Link} to="/">
          Home
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFound404;
