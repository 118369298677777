import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const FontNameDisplay = ({ fontName }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems="center">
      <Stack direction={'row'} spacing={1} alignItems="center">
        <Tooltip title="Go Home">
          <Button
            sx={{ textTransform: 'none' }}
            to="/"
            component={Link}
            preventScrollReset={true}
          >
            <Typography
              sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              color="primary"
            >
              {' '}
              <ArrowBack fontSize="inherit" /> fonts
            </Typography>
          </Button>
        </Tooltip>
        <Typography sx={{ opacity: 0.5 }} color="primary">
          {'/'}
        </Typography>
      </Stack>

      <Typography fontWeight={'bold'}>{fontName}</Typography>
    </Stack>
  );
};
