import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actions, selectors } from '../../redux';
import axios from 'axios';
import { K } from '../../configs';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Paper } from '@mui/material';
import { ErrorState } from '../../components/ErrorState/ErrorState';
import { LoadingState } from '../../components/LoadingState/LoadingState';
import {
  formatFontsObject,
  injectFontElementCSS,
} from '../../helpers/functions';

export const MainLayout = () => {
  const containerStyle = {
    height: 'calc(100vh - 50px)',
    overflowY: 'auto',
    transition: 'var(--transition)',
  };

  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectors.selectLoadingStatus);
  const fonts = useSelector(selectors.selectFonts);
  const isLoading = loadingStatus === K.LOADING_STATUS.pending;
  const error = loadingStatus === K.LOADING_STATUS.failed;

  useEffect(() => {
    dispatch(actions.setFontsLoadingStatus(K.LOADING_STATUS.pending));
    axios
      .get(K.FONT_LIST_JSON_URL)
      .then(({ data }) => {
        setTimeout(() => {
          dispatch(actions.setFontsLoadingStatus(K.LOADING_STATUS.idle));
          dispatch(actions.setFonts(data));
          dispatch(actions.setFontsObject(formatFontsObject(data)));
          injectFontElementCSS(data);
        }, 1000);
      })
      .catch((err) => {
        console.log('Error loading fonts.', err);
        dispatch(actions.setFontsLoadingStatus(K.LOADING_STATUS.failed));
      });
  }, []);

  if (error) {
    return (
      <Paper sx={containerStyle} elevation={0}>
        <ErrorState message={'Error loading fonts'} />
      </Paper>
    );
  }

  if (!fonts.length || isLoading) {
    return (
      <Paper sx={containerStyle} elevation={0}>
        <LoadingState />
      </Paper>
    );
  }

  return (
    <Paper sx={containerStyle} elevation={0}>
      <Outlet />
      <ScrollRestoration />
    </Paper>
  );
};
