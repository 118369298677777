export const muiThemeOverrides = {
  components: {
    MuiInputBase: {
      defaultProps: {
        sx: {
          '& input:focus::placeholder': {
            color: '#1a73e8',
            opacity: '1',
          },
        },
      },
    },
  },
};
